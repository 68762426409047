//#region ng
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
//#endregion

//#region firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
//#endregion

//#region 3rd
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
//#endregion

//#region modules
import { AppRoutingModule } from './app-routing.module';
import { ConecdataSharedModule } from './modules/_conecdata/_shared/conecdata-shared.module';
//#endregion

//#region models
import { environment } from '../environments/environment';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // "bgsColor": "red",
  // "bgsOpacity": 0.5,
  // "bgsPosition": "bottom-right",
  // "bgsSize": 60,
  // "bgsType": "ball-spin-clockwise",
  "blur": 2,
  // "delay": 0,
  "fgsColor": "#ffffff",
  "fgsPosition": "center-center",
  "fgsSize": 150,
  "fgsType": "fading-circle",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(0,0,0,0.7)", // "rgba(244,98,58,0.5)", // #F4623A
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  // "maxTime": -1,
  // "minTime": 500
};
// https://ngx-ui-loader.stackblitz.io/

/* {
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.chasingDots, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
}; */
//#endregion

//#region components
import { AppComponent } from './app.component';
import { DependenteAgregadoComponent } from './modals/dependente-agregado/dependente-agregado.component';
//#endregion

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ConecdataSharedModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule, // .enablePersistence(),,
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
  ],
  declarations: [
    AppComponent,
    DependenteAgregadoComponent
  ],
  entryComponents: [
    DependenteAgregadoComponent
  ],
  providers: [
    // AngularFireAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
