//#region ng
import {
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Renderer,
} from '@angular/core';
//#endregion

//#region directive
@Directive({
    selector: '[select]'
})
export class SelectDirective implements OnInit {

    //#region comm
    @Input('select') public selectEvent: EventEmitter<boolean>;
    //#endregion

    //#region contructor
    constructor(
        @Inject(ElementRef) private element: ElementRef,
        private renderer: Renderer,
    ) { }
    //#endregion

    //#region lifecycles
    ngOnInit() {
        if (this.selectEvent) {
            this.selectEvent.subscribe(
                event => {
                    // console.log('FocusDirective', event);
                    this.renderer.invokeElementMethod(this.element.nativeElement, 'focus', []);
                    this.renderer.invokeElementMethod(this.element.nativeElement, 'select', []);
                });
        }; // if
    }
    //#endregion
}
//#endregion
