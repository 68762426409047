import { AbstractControl, NgModel } from "@angular/forms";

export class FormValidation {
  //#region privates
  private _errosApi: any;
  //#endregion

  //#region methods
  badTouch(campo: AbstractControl | NgModel): boolean {
    if (campo) {
      return campo.invalid && campo.touched;
    } else {
      return false;
    } // else
  }

  /* submittedError(campo: NgModel, apiId = ""): string {
    // console.log(campo);
    if (this._submitted) {
      console.log(this.error(campo, apiId));
      return this.error(campo, apiId);
    } else {
      return "";
    } // else
  } */

  serror(
    submitted: boolean,
    campo: AbstractControl | NgModel,
    apiId = ""
  ): string {
    // console.log(this._submitted);
    if (submitted) {
      return this.error(campo, apiId);
    } else {
      return "";
    } // else
  }

  lerror(
    campo: AbstractControl | NgModel,
    apiId = ""
  ): string {
    // console.log(this._submitted);
    if (campo.touched || campo.dirty) {
      return this.error(campo, apiId);
    } else {
      return "";
    } // else
  }

  error(campo: AbstractControl | NgModel, apiId = ""): string {
    // console.log(campo);
    // console.log(campo.name, apiId);
    if (campo) {
      if (campo.valid) {
        // Se não há erros de validação do form, verifica retorno da API.
        if (apiId && this._errosApi) {
          // Retorna msg da API (ou vazia).
          return this._errosApi[apiId] || "";
        } else {
          // Se não há verificação da API, não há erros.
          return "";
        } // if
      } else {
        let S = "";
        // Retorna lista de problemas na validação do form.
        if (campo.errors.required) {
          S += "Valor é obrigatório.\n";
        } else if (campo.errors.minlength) {
          S += "Largura mínima não atingida.\n";
        } else if (campo.errors.maxlength) {
          S += "Largura máxima atingida.\n";
        } else if (campo.errors.min) {
          S += "Valor mínimo não atingido.\n";
        } else if (campo.errors.max) {
          S += "Valor máximo atingido.\n";
        } else if (campo.errors.rangeLength) {
          S += "Valor fora do intervalo válido.\n";
        } else if (campo.errors.gt) {
          S += "Valor superior ao válido.\n";
        } else if (campo.errors.gte) {
          S += "Valor superior ao válido.\n";
        } else if (campo.errors.lt) {
          S += "Valor inferior ao válido.\n";
        } else if (campo.errors.lte) {
          S += "Valor inferior ao válido.\n";
        } else if (campo.errors.url) {
          S += "Não é uma URL válida.\n";
        } else if (campo.errors.email) {
          S += "Não é um email válido.\n";
        } else if (campo.errors.number) {
          S += "Não é um número válido.\n";
        } else if (campo.errors.date) {
          S += "Não é uma data válida.\n";
        } else if (campo.errors.minDate) {
          S += "Data abaixo do limite.\n";
        } else if (campo.errors.maxDate) {
          S += "Data acima do limite.\n";
        } else if (campo.errors.dateISO) {
          S += "Formato da data inválido.\n";
        } else if (campo.errors.uuid) {
          S += "Identificador inválido.\n";
        } // if
        return S;
      } // else
    } else {
      return "";
    } // else
  }

  setApiErrors(errors: any): void {
    this._errosApi = errors;
  }
  //#endregion
}
