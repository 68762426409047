//#region ng
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
//#endregion

//#region 3rd
import { Observable } from "rxjs";
//#endregion

//#region models
import { ICid10 } from '../models/interfaces';
//#endregion

@Injectable({
    providedIn: "root"
})
export class Cid10Service {
    //#region models
    readonly ATILADANVI_API = "https://cid10-api.herokuapp.com/cid10";
    //#endregion

    //#region constructor
    constructor(private _http: HttpClient) { }
    //#endregion

    cid(cod: string): Observable<ICid10> {
        const URL = encodeURI(`${this.ATILADANVI_API}/${cod}`);
        return this._http.get<ICid10>(URL);
    }

    cids(): Observable<ICid10[]> {
        const URL = this.ATILADANVI_API;
        // console.log(URL);
        return this._http.get<ICid10[]>(URL);
    }
}

// https://github.com/Atiladanvi/cid10-api