//#region ng
import { Injectable } from '@angular/core';
//#endregion

//#region firebase
import { AngularFirestore } from '@angular/fire/firestore';
// import OrderByDirection = firebase.firestore.OrderByDirection;
//#endregion

//#region 3rd
import { Observable } from "rxjs";
import { map, first } from "rxjs/operators";
//#endregion

//#region models
import { IUf } from '../models/interfaces';
import { ROWS_TAG } from '../models/consts';
//#endregion

//#region lib
import { flatSnap } from '../modules/_firebase/_shared/_lib';
//#endregion

//#region services
// import { PedeonService } from './pedeon.service';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class UfsService {
    //#region constructor    
    constructor(
        private _db: AngularFirestore
    ) { }
    //#endregion

    //#region methods C
    //#endregion

    //#region methods R
    ufs(): Observable<any> {
        const PATH: string = '/ufs';
        return this._db.collection(PATH)
            .doc(ROWS_TAG)
            .snapshotChanges()
            .pipe(
                map(snap =>
                    // console.log(snap)
                    flatSnap<IUf>(snap)
                    // snap.data()
                ),
                first()
            );
    }
    //#endregion

    //#region U
    //#endregion

    //#region D
    //#endregion
}
