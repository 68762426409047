//#region ng
import {
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Renderer
} from "@angular/core";
//#endregion

//#region directive
@Directive({
    selector: "[focus]"
})
export class FocusDirective implements OnInit {
    //#region comm
    @Input("focus") public focusEvent: EventEmitter<boolean>;
    //#endregion

    //#region contructor
    constructor(
        @Inject(ElementRef) private element: ElementRef,
        private renderer: Renderer
    ) { }
    //#endregion

    //#region lifecycles
    ngOnInit() {
        // console.log(this.element.nativeElement);
        if (this.focusEvent) {
            this.focusEvent.subscribe(event => {
                // console.log('FocusDirective', event);
                setTimeout(() => {
                    // this.element.nativeElement.setFocus();
                    this.renderer.invokeElementMethod(this.element.nativeElement, 'focus', []);
                }, 100);
                // this.renderer.invokeElementMethod(this.element.nativeElement, 'select', []);
            });
        } // if
    }
    //#endregion
}
  //#endregion
