// import { flatSnap } from "./flat-snap";

export function flatSnaps<T>(snaps) {
    // console.log(snaps);
    return <T[]>snaps.map(snap => {
        return {
            id: snap.payload.doc.id,
            ...snap.payload.doc.data()
        };
    });
};

// export function flatSnaps<T>(snaps) {
//     // console.log(snaps);
//     if (snaps) {
//         return <T[]>snaps.map(snap => {
//             flatSnap<T>(snap);
//         });
//     } else {
//         return null;
//     } // else
// };
