//#region ng
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  OnDestroy
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
//#endregion

//#region 3rd
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { NgxUiLoaderService } from 'ngx-ui-loader';
//#endregion

//#region models
import { FormValidation } from "../../modules/_conecdata/_models/_classes";
//#endregion

//#region services
import { Cid10Service } from "../../services";
import { IDependenteAgregado } from "src/app/models/interfaces";
//#endregion

@Component({
  selector: "app-dependente-agregado",
  templateUrl: "./dependente-agregado.component.html",
  styleUrls: ["./dependente-agregado.component.scss"]
})
export class DependenteAgregadoComponent implements OnInit, AfterViewInit, OnDestroy {
  //#region publics
  cadastroForm: FormGroup;
  fv: FormValidation;
  dep: IDependenteAgregado;
  //#endregion

  //#region privates
  private _subs: Subscription[] = [];
  //#endregion

  //#region methods
  onNomeFocusEvent: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  //#endregion

  //#region constructor
  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: FormBuilder,
    private _cid10Serv: Cid10Service,
    private _ngxLoaderServ: NgxUiLoaderService
  ) {
    // classes
    this.fv = new FormValidation();

    // forms
    this.cadastroForm = this.formBuilder.group({
      /* 
        cidade: [
          "",
          Validators.compose([Validators.maxLength(120), Validators.required])
        ],
        estado: [
          "",
          Validators.compose([Validators.maxLength(2), Validators.required])
        ]
        */

      nome: [
        "",
        Validators.compose([Validators.maxLength(60), Validators.required])
      ],
      sexo: [
        "",
        Validators.compose([Validators.maxLength(1), Validators.required])
      ],
      idade: ["", Validators.compose([Validators.required])],
      dtAfastamento: [
        null
        // Validators.compose([Validators.required])
      ],
      tipoAfastamento: [
        ""
        // Validators.compose([Validators.required])
      ],
      tipoDoenca: [
        ""
        // Validators.compose([Validators.required])
      ],
      cid: [
        ""
        // Validators.compose([Validators.required])
      ],
      legendaCid: [
        ""
        // Validators.compose([Validators.required])
      ]
    });
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    /* this._subs.push(
      this.cadastroForm.get("cid").valueChanges.subscribe((cid: string) => {
        cid = cid.toUpperCase() || "";
        console.log(cid);

        this.cadastroForm.controls.legendaCid.setValue(null);
        this._cid10Serv.cid(cid).subscribe(val => {
          const CID = val.nome || "CID inválido ou não encontrado.";
          console.log(val, CID);
          this.cadastroForm.controls.legendaCid.setValue(CID);
        });
      })
    ); */

    this.dep = null;
  }

  ngAfterViewInit() {
    this._focus();
  }

  ngOnDestroy(): void {
    this._subs.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      } // if
    });
  }
  //#endregion

  //#region functions
  private _focus() {
    setTimeout(() => {
      this.onNomeFocusEvent.emit();
    }, 0);
  }
  //#endregion

  //#region methods
  onSubmit() {
    this.dep = this.cadastroForm.value;
    this.bsModalRef.hide();
  }

  onCidBlur() {
    // cid = cid.toUpperCase() || "";
    const CID = this.cadastroForm.value.cid ? this.cadastroForm.value.cid.toUpperCase() : '';
    // console.log(CID);

    this.cadastroForm.controls.legendaCid.setValue(null);
    if (CID) {
      this._ngxLoaderServ.start();
      this._cid10Serv.cid(CID)
        .subscribe(val => {
          const LEGENDA_CID = val.nome || "CID inválido ou não encontrado.";
          console.log(val, LEGENDA_CID);
          this.cadastroForm.controls.legendaCid.setValue(LEGENDA_CID);
          this._ngxLoaderServ.stop();
        });
    } // if
  }
  //#endregion
}
