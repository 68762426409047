//#region ng
import {
  AfterViewInit,
  Component,
  HostListener,
  EventEmitter,
  OnDestroy,
  OnInit,
  TemplateRef
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
//#endregion

//#region 3rd
import * as $ from "jquery";
import { Subscription } from "rxjs";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import Swal from "sweetalert2";
import { NgxUiLoaderService } from 'ngx-ui-loader';
//#endregion

//#region models
const LIMIT_OFFSET = 20;
type TipoDependencia = "dependente" | "agregado";
import { FormValidation } from "./modules/_conecdata/_models/_classes";
import { ICidade, IDependenteAgregado, IUf } from "./models/interfaces";
//#endregion

//#region services
import {
  Cid10Service,
  CidadesService,
  TitularesService,
  UfsService
} from "./services";
//#endregion

//#region modals
import { DependenteAgregadoComponent } from "./modals";
//#endregion

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  //#region comm
  @HostListener("window:scroll", ["$event"])
  onScrollUpdate(event) {
    this._scrollChanged();
  }
  //#endregion

  //#region publics
  depModalRef: BsModalRef;
  delModalRef: BsModalRef;
  atLimit: boolean = true;
  cadastroForm: FormGroup;
  fv: FormValidation;
  ufs: IUf[];
  cidades: ICidade[];
  placeholders = {
    cidade: ""
  };
  cidade: any;
  dependentes: IDependenteAgregado[] = [];
  idDependente: number = 0;
  delDependente: IDependenteAgregado;
  // dataSource$: Observable<ICidade[]> = of([]);
  //#endregion

  //#region cidadeOk
  private _cidadeOk: boolean;
  set cidadeOk(val: boolean) {
    this.placeholders.cidade = val
      ? "Digite o início do nome da cidade"
      : "Selecione um estado primeiro";
    this._cidadeOk = val;
  }
  get cidadeOk(): boolean {
    return this._cidadeOk;
  }
  //#endregion

  //#region privates
  private _subs: Subscription[] = [];
  //#endregion

  //#region methods
  onEstadoFocusEvent: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  onCidadeFocusEvent: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  //#endregion

  //#region constructor
  constructor(
    public formBuilder: FormBuilder,
    private _ufsServ: UfsService,
    private _cidadesServ: CidadesService,
    private _cid10Serv: Cid10Service,
    private _modalServ: BsModalService,
    private _titularesServ: TitularesService,
    private _ngxLoaderServ: NgxUiLoaderService
  ) {
    // classes
    this.fv = new FormValidation();

    // forms
    this.cadastroForm = this.formBuilder.group({
      cidade: [
        "",
        Validators.compose([Validators.maxLength(120), Validators.required])
      ],
      estado: [
        "",
        Validators.compose([Validators.maxLength(2), Validators.required])
      ],
      matricula: [
        "",
        Validators.compose([Validators.maxLength(10), Validators.required])
      ],
      cargoFuncao: [
        "",
        Validators.compose([Validators.maxLength(60), Validators.required])
      ],
      nome: [
        "",
        Validators.compose([Validators.maxLength(60), Validators.required])
      ],
      sexo: [
        "",
        Validators.compose([Validators.maxLength(1), Validators.required])
      ],
      idade: ["", Validators.compose([Validators.required])],
      dtAfastamento: [
        null
        // Validators.compose([Validators.required])
      ],
      tipoAfastamento: [
        ""
        // Validators.compose([Validators.required])
      ],
      tipoDoenca: [
        ""
        // Validators.compose([Validators.required])
      ],
      cid: [
        ""
        // Validators.compose([Validators.required])
      ],
      legendaCid: [
        ""
        // Validators.compose([Validators.required])
      ]
    });

    this.cidadeOk = false;
  }
  //#endregion

  //#region lifecycles
  ngOnInit() {
    this._subs.push(
      this.cadastroForm
        .get("estado")
        .valueChanges.subscribe((estado: string) => {
          estado = estado ? estado.toLowerCase() : "";
          // console.log(estado);
          this.cidadeOk = !!estado;

          // Lê cidades da coleção e gera documento ROWS
          // this._cidadesServ.cidades(estado)
          //   .subscribe((cidades: ICidade[]) => {
          //     // console.log(cidades);
          //     this._cidadesServ.updateRows(estado, cidades)
          //       .subscribe(val => console.log(val));
          //   });

          // Lê cidades de ROWS
          // this.dataSource$ =
          this.cidades = [];
          if (estado) {
            this._cidadesServ.cidades(estado)
              .subscribe((cidades: ICidade[]) => {
                // console.log(cidades);
                this.cidades = cidades || [];
                this._focusCidade();
              });
          } // if
        }),
      /* this.cadastroForm.get("cid").valueChanges.subscribe((cid: string) => {
        cid = cid ? cid.toUpperCase() : '';
        // console.log(cid);

        this.cadastroForm.controls.legendaCid.setValue(null);
        if (cid) {
          this._ngxLoaderServ.start();
          this._cid10Serv.cid(cid).subscribe(val => {
            const CID = val.nome || "CID inválido ou não encontrado.";
            // console.log(val, CID);
            this.cadastroForm.controls.legendaCid.setValue(CID);
          });
        } // if
      }), */
      this._ufsServ.ufs().subscribe(val => {
        // console.log(val);
        this.ufs = val.rows;
      })
    );
  }

  ngAfterViewInit() {
    this._scrollChanged();

    const POS = $(".masthead").offset().top || 0;
    // console.log(POS);
    $("html, body").animate(
      {
        scrollTop: POS
      },
      "slow"
    );

    // this._focusEstado();
  }

  ngOnDestroy(): void {
    this._subs.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      } // if
    });
  }
  //#endregion

  //#region functions
  private _scrollChanged() {
    // console.log('document.body.scrollTop', document.body.scrollTop);
    // console.log('document.documentElement.scrollTop', document.documentElement.scrollTop);
    const AT_TOP = !(
      document.body.scrollTop > LIMIT_OFFSET ||
      document.documentElement.scrollTop > LIMIT_OFFSET
    );
    // const AT_BOTTOM = window.innerHeight + window.scrollY >= document.body.offsetHeight;
    // console.log(AT_TOP, AT_BOTTOM);
    this.atLimit = AT_TOP;
    if (AT_TOP) {
      $("#mainNav").removeClass("navbar-scrolled");
    } else {
      $("#mainNav").addClass("navbar-scrolled");
    } // else
  }

  private _focusEstado() {
    setTimeout(() => {
      this.onEstadoFocusEvent.emit();
    }, 0);
  }

  private _focusCidade() {
    setTimeout(() => {
      this.onCidadeFocusEvent.emit();
    }, 0);
  }
  //#endregion

  //#region methods
  onSubmit() {
    let changes = {
      ...this.cadastroForm.value,
      dependentes: this.dependentes
    };
    changes.criadoEm = new Date();
    this._titularesServ.add(changes).subscribe(val => {
      // console.log(val);
      this.cadastroForm.reset();
      this.cadastroForm.controls.estado.setValue('');
      this.cadastroForm.controls.sexo.setValue('');
      // this.cadastroForm.value.estado.setValue('');
      // this.cadastroForm.value.sexo.setValue('');
      this.dependentes = [];
      this.idDependente = 0;

      Swal.fire({
        icon: "success",
        title: "Cadastro enviado com sucesso."
        // text: "ENVIAR CADASTRO",
      });
    });
  }

  /* onCidadeChange(filter: string) {
    // const FILTER = this.cadastroForm.controls.cidade.value;
    const ESTADO = this.cadastroForm.controls.estado.value;
    console.log(ESTADO, filter);
    if (filter && filter.length > 2) {
      // this.dataSource$ = this._cidadesServ.cidades(ESTADO, filter.toLowerCase());
      // console.log(this.dataSource$);
      // .subscribe(cidades => {
      //   console.log(cidades);
      //   // this.cidades = cidades.rows;
      //   this.dataSource = cidades.rows; // of(this.statesComplex);
      // });
    } else {
      this.dataSource$ = of([]);
    } // else
  } */

  onSelect(val) {
    // console.log(val);
  }

  onNovoClick(tipo: TipoDependencia) {
    let sub: Subscription;

    const CONFIG = {
      animated: true,
      backdrop: true,
      keyboard: true,
      initialState: { tipo }
    };

    sub = this._modalServ.onHide.subscribe((reason: string) => {
      // console.log(reason);
      const DEP = {
        id: ++this.idDependente,
        tipoDependencia: tipo,
        ...this.depModalRef.content.dep,
      };

      if (DEP) {
        this.dependentes.push(DEP);
      } // if

      console.log();
      // const _reason = reason ? `, dismissed by ${reason}` : "";
      // this.messages.push(`onHide event has been fired${_reason}`);
      sub.unsubscribe();
    });

    this.depModalRef = this._modalServ.show(DependenteAgregadoComponent, CONFIG);
    // this.bsModalRef.content.closeBtnName = 'Close';
  }

  onDeleteDependenteClick(dep: IDependenteAgregado, template: TemplateRef<any>) {
    console.log(dep);
    this.delDependente = dep;

    this.delModalRef = this._modalServ.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }

  onConfirmDelClick() {
    this.dependentes = this.dependentes.filter((d: IDependenteAgregado) =>
      d.id != this.delDependente.id
    );
    this.delModalRef.hide();
  }

  onCidBlur() {
    console.log('onCidBlur');
    // this.cadastroForm.get("cid").valueChanges.subscribe((cid: string) => {
    // cid = cid ? cid.toUpperCase() : '';
    const CID = this.cadastroForm.value.cid ? this.cadastroForm.value.cid.toUpperCase() : '';
    // console.log(CID);

    this.cadastroForm.controls.legendaCid.setValue(null);
    if (CID) {
      this._ngxLoaderServ.start();
      this._cid10Serv.cid(CID)
        .subscribe(val => {
          const LEGENDA_CID = val.nome || "CID inválido ou não encontrado.";
          // console.log(val, LEGENDA_CID);
          this.cadastroForm.controls.legendaCid.setValue(LEGENDA_CID);
          this._ngxLoaderServ.stop();
        });
    } // if
    // })
  }
  //#endregion
}
