//#region ng
import {
    Directive,
    ElementRef,
    HostListener,
    Input,
} from '@angular/core';
//#endregion

//#region directive
@Directive({
    selector: '[onReturn]'
})
export class OnReturnDirective {

    //#region comm
    @Input() onReturn: string;
    //#endregion

    //#region constructor
    constructor(private _el: ElementRef) { }
    //#endregion

    //#region body
    @HostListener('keydown', ['$event']) onKeyDown(e) {
        if (e.which == 13 || e.keyCode == 13) {
            e.preventDefault();
            var focusable;
            if (this._el
                && this._el.nativeElement
                && this._el.nativeElement.form) {
                focusable = this._el.nativeElement.form.querySelectorAll('input,select,button,textarea');
            }; // if

            if (focusable) {
                // console.log(focusable);
                var currentIndex = Array.prototype.indexOf.call(focusable, e.target)
                var nextIndex = currentIndex == focusable.length - 1 ? 0 : currentIndex + 1;
                if (nextIndex >= 0 && nextIndex < focusable.length)
                    focusable[nextIndex].focus();
            }; // if
            return;
        }; // if
    }
    //#endregion
}
//#endregion