//#region ng
import { Injectable } from '@angular/core';
//#endregion

//#region firebase
import { AngularFirestore } from '@angular/fire/firestore';
// import OrderByDirection = firebase.firestore.OrderByDirection;
//#endregion

//#region 3rd
import { Observable, from } from "rxjs";
import { map, first } from "rxjs/operators";
//#endregion

//#region models
import { ICidade } from '../models/interfaces';
import { ROWS_TAG } from '../models/consts';
//#endregion

//#region lib
import { flatSnap, flatSnaps } from '../modules/_firebase/_shared/_lib';
//#endregion

//#region services
// import { PedeonService } from './pedeon.service';
//#endregion

@Injectable({
    providedIn: 'root'
})
export class CidadesService {
    //#region constructor    
    constructor(
        private _db: AngularFirestore
    ) { }
    //#endregion

    //#region methods C

    //#endregion

    //#region methods R
    cidades(estado: string): Observable<any> {
        const ESTADO: string = estado || '';
        const PATH: string = `/ufs/${ESTADO}/cidades`;
        // console.log(PATH);
        return this._db.collection(PATH, ref => ref
            .orderBy('nome')
        )
            .snapshotChanges()
            .pipe(
                // tap(snap => console.log(snap))
                map(snap =>
                    // console.log(snap)
                    flatSnaps<ICidade>(snap)
                    // snap.payload.data()
                ),
                first()
            );
    }

    rows(estado: string): Observable<any> {
        const ESTADO: string = estado || '';
        const PATH: string = `/ufs/${ESTADO}/cidades/${ROWS_TAG}`;
        console.log(PATH);
        return this._db.doc(PATH)
            .snapshotChanges()
            .pipe(
                // tap(snap => console.log(snap))
                map(snap =>
                    // console.log(snap)
                    flatSnap<ICidade>(snap)
                    // snap.payload.data()
                ),
                first()
            );
    }
    //#endregion

    //#region U
    updateRows(estado: string, cidades: ICidade[]): Observable<any> {
        const ESTADO: string = estado || '';
        const CIDADES: ICidade[] = cidades || [];
        const PATH: string = `/ufs/${ESTADO}/cidades/${ROWS_TAG}`;
        // console.log(PATH);
        // console.log(CIDADES);
        return from(this._db.doc(PATH)
            .set({ rows: CIDADES }, { merge: true }))
    }
    //#endregion

    //#region D
    //#endregion
}
