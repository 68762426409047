//#region ng
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
//#endregion

//#region ionic
// import { IonicModule } from "@ionic/angular";
//#endregion

//#region 3rd
// import { Ng2BRPipesModule } from 'ng2-brpipes';
//#endregion

//#region app directives
import {
  FocusDirective,
  SelectDirective,
  OnReturnDirective,
} from "./_directives";
//#endregion

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    // IonicModule,
    FormsModule,
    // Ng2BRPipesModule
  ],
  declarations: [
    // components
    // directives
    FocusDirective,
    SelectDirective,
    OnReturnDirective,
    // pipes
  ],
  exports: [
    // components
    // directives
    FocusDirective,
    SelectDirective,
    OnReturnDirective,
    // pipes
  ]

})
export class ConecdataSharedModule { }
