export function flatSnap<T>(snap) {
    // console.log(snap);
    if (snap) {
        if (snap.payload) {
            return <T>{
                id: snap.payload.id,
                ...snap.payload.data()
            };
        } else {
            return <T>{
                id: snap.id,
                ...snap.data()
            };
        } // else
    } else {
        return null;
    } // else
};
