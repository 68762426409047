export const getCollectionRefs = async (refs) => {
    // console.log(refs);
    let gets = refs.map(ref => ref.get());
    // console.log(gets);
    const result = await Promise.all(gets);
    return result.map((v: any) => {
        // console.log(v);
        return {
            id: v.id,
            ref: v.ref, // path: v.ref.path
            ...v.data()
        }
        // return v.data();
    });
}

// getCollectionRefs([ref1, ref2, ref3...]);