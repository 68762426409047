//#region ng
import { Injectable } from "@angular/core";
//#endregion

//#region firebase
import { AngularFirestore } from "@angular/fire/firestore";
// import OrderByDirection = firebase.firestore.OrderByDirection;
//#endregion

//#region 3rd
import { Observable, from } from "rxjs";
// import { map, first, tap } from "rxjs/operators";
//#endregion

//#region models
import { ITitular } from "../models/interfaces";
// import { ROWS_TAG } from '../../_models/consts';
//#endregion

//#region lib
// import { flatSnaps } from "../modules/_firebase/_shared/_lib";
//#endregion

@Injectable({
  providedIn: "root"
})
export class TitularesService {
  //#region constructor
  constructor(private _db: AngularFirestore) {}
  //#endregion

  //#region C
  add(titular: ITitular): Observable<any> {
    const PATH: string = `/titulares`;
    return from(this._db.collection(PATH).add(titular));
  }
  //#endregion

  //#region R
  //#endregion

  //#region U
  //#endregion

  //#region D
  //#endregion
}
